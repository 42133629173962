<template>
    <div class="section-four w-100 mt-lg-0 mt-5 mb-0 mb-lg-4 min-vh-50">
        <div class="row justify-content-center">
            <div class="col-10 py-0" v-if="!isLoad">
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="nav-blog">
                            <div class="item" :class="{'active' : tab == 'popular'}">
                                <a href="javascript:void(0)" class="" @click="tab = 'popular'">Popular</a>
                            </div>
                            <div class="item" :class="{'active' : tab == 'recent'}">
                                <a href="javascript:void(0)" class="" @click="tab = 'recent'">Recent</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row" v-if="tab == 'popular'">
                            <div class="col-lg-3 col-6 mb-4" v-for="(item, index) in data.popular" :key="index">
                                <img :src=" item.thumbnail" class="mb-2" width="100%" alt="">
                                <router-link :to="{name: 'BlogDetailBrowseNew', params: {idBlog: item.id}}" class="fw-700 text-black font-xss">{{ item.title }}</router-link>
                            </div>
                        </div>
                        <div class="row" v-if="tab == 'recent'">
                            <div class="col-lg-3 col-6 mb-4" v-for="(item, index) in data.recent" :key="index">
                                <img :src=" item.thumbnail" class="mb-2" width="100%" alt="">
                                <router-link :to="{name: 'BlogDetailBrowseNew', params: {idBlog: item.id}}" class="fw-700 text-black font-xss">{{ item.title }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-10 p-0 text-center" v-else>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['data', 'isLoad'],
    data() {
        return {
            media : process.env.VUE_APP_URL_CLOUD,
            tab: 'popular'
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {

}
.nav-blog {
    display: flex;
}
.nav-blog .item {
    padding: 5px 15px;
    
}
.nav-blog .item.active {
    /* position: absolute */
    border-top: 3px solid #000;
    border-left: 3px solid #000;
    border-right: 3px solid #000;
}
.nav-blog .item:not(.active){
    border-bottom: 3px solid #000;
}
.nav-blog .item a{
    font-size: 21px;
    font-weight: 600;
    color: #000;
}
</style>