<template>
    <div class="section-three w-100 pt-lg-0 pt-3 mt-lg-0 mt-5 mb-0 min-vh-50">
        <div class="row justify-content-center">
            <div class="col-10 py-0">
                <div class="row">
                    <div class="col-lg-2">
                        <div class="recent-box">
                            <img :src="'/images/bg-blog/sec3-line-image.svg'" class="line-image" alt="">
                            <p class="fw-800 text-black font-xl pl-lg-5">Recent</p>
                            <router-link :to="{name: 'BlogListBrowseNew'}" class="fw-400 font-xssss pl-5 more-text">MORE ON RECENT</router-link>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <div class="row justify-content-end">
                            <div class="col-12" v-if="!isLoad">
                                <div class="row">
                                    <div class="col-lg-3 col-6 mb-4" v-for="(item, index) in data.recent" :key="index">
                                        <img :src=" item.thumbnail" class="mb-2" width="100%" alt="">
                                        <router-link :to="{name: 'BlogDetailBrowseNew', params: {idBlog: item.id}}" class="fw-700 text-black font-xss">{{item.title}}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-else>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['data', 'isLoad'],
    data() {
        return {
            media : process.env.VUE_APP_URL_CLOUD,
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .recent-box .more-text {
        position: inherit !important;
        padding-top: 13px;
        text-align: right;
        width: 100%;
    }
    .recent-box {
        display: flex !important;
        height: fit-content !important;
    }
    .recent-box .line-image {
        display: none !important;
    }
}
.recent-box {
    position: relative;
    height: 250px;
}
.recent-box .line-image {
    position: absolute;
    height: 250px;
}
.recent-box .more-text {
    position: absolute;
    bottom: 0;
}

</style>