<template>
    <div class="row m-0 p-0">
        <div class="col-12 pt-7 p-0 m-0">
            <SectionOne :is-load="isLoad" :data="data"></SectionOne>
            <SectionTwo :is-load="isLoad" :data="data"></SectionTwo>
            <SectionThree :is-load="isLoad" :data="data"></SectionThree>
            <SectionFour :is-load="isLoad" :data="data"></SectionFour>
        </div>
    </div>
</template>
<script>
import SectionOne from './Section/SectionOne.vue'
import SectionTwo from './Section/SectionTwo.vue'
import SectionThree from './Section/SectionThree.vue'
import SectionFour from './Section/SectionFour.vue'
import axios from 'axios'
export default {
    data() {
        return {
            isLoad: true,
            data: {
                headline: {
                    title: 'Learning High Valyrian on Elingway brought me nothing but joy in 2022',
                    sub_title: 'AN APP OF NOUNS AND VERBS',
                    st_first_title: 'SUB TEXT',
                    st_first_description: 'The internet celebrates Harry Meguire’s most iconic moments',
                    st_second_title: 'SUB TEXT',
                    st_second_description: 'How to move on after a situationship ends',
                    st_third_title: 'SUB TEXT',
                    st_third_description: 'How to move on after a situationship ends',
                },
                main: {
                    thumbnail: '',
                    title: '',
                    content: '',
                },
                popular: [],
                recent: []
            }
        }
    },
    created() {
        this.getMainBlog()
    },
    methods: {
        async getMainBlog() {
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/blog/article/main`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                let data = res.data.data
                if(data.headline) {
                    this.data.headline = data.headline
                }
                if(data.main) {
                    this.data.main = data.main
                }
                this.data.popular = data.popular
                this.data.recent = data.recent
                this.isLoad = false
            })
        },
    },
    components: {
        SectionOne,
        SectionTwo,
        SectionThree,
        SectionFour
    }
}
</script>