<template>
    <div class="section-two w-100 pt-lg--7 mb-0 mb-lg-4">
        <div class="row justify-content-center">
            <div class="col-10 py-0">
                <div class="row justify-content-center">
                    <div class="col-lg-8 mb-5">
                        <div class="main-headline">
                            <img :src="data.main.thumbnail ?  data.main.thumbnail : 'https://via.placeholder.com/4000x3000.png/000000/000000'" width="100%" alt="">
                            <div class="text-box" v-if="data.main.id">
                                <router-link :to="{name: 'BlogDetailBrowseNew', params: {idBlog: data.main.id}}" class="font-md text-black fw-600 text-white">{{ data.main.title }}</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="trending-headline mb-4 mb-lg-3">
                            <span class="font-xxl fw-700 text-black">TRENDING</span>
                        </div>
                        <div class="row">
                            <div class="col-12" v-if="!isLoad">
                                <div class="row mb-3 trending-box" v-for="(item, index) in data.popular" :key="index">
                                    <div class="col-lg-6 col-7 pr-0 text-left">
                                        <!-- <p class="fw-500 text-black font-xsss mb-2">MUSIC</p> -->
                                        <router-link :to="{name: 'BlogDetailBrowseNew', params: {idBlog: item.id}}" class="fw-700 text-black font-xss">{{ item.title }}</router-link>
                                    </div>
                                    <div class="col-lg-6 col-5">
                                        <img :src=" item.thumbnail" width="100%" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3 text-center" v-else>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['data', 'isLoad'],
    data() {
        return {
            media : process.env.VUE_APP_URL_CLOUD,
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .main-headline .text-box {
        width: 100% !important;
        padding: 10px 15px !important;
    }
    .main-headline .text-box .font-md{
        font-size: 16px !important;
    }
    .trending-headline {
        height: 70px !important;
        padding-left: 20px !important;
        padding-top: 8px !important;
    }
    .trending-headline .font-xxl {
        font-size: 24px !important;
    }
    .trending-box .font-xss {
        font-size: 14px !important;
    }
}
.trending-headline {
    width: 100%;
    text-align: center;
    background-image: url('/images/bg-blog/sec1-box-image.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100px;
    padding-left: 40px;
    padding-top: 10px;
}
.main-headline {
    position: relative;
    margin-bottom: 20px;
}
.main-headline .text-box {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 30px;
    width: 60%;
}
</style>