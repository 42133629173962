<template>
    <div class="section-one w-100 mb-lg-0 mb-4 pt-10">
        <div class="row justify-content-center">
            <div class="col-10 py-0">
                <div class="banner-box">
                    <img height="250px" width="100%" :src="'/images/bg-blog/sec1-box-top-image.png'" alt="">
                    <div class="text-box">
                        <p class="text-black fw-500 font-xsss mb-2">{{ data.headline.sub_title }}</p>
                        <p class="text-black fw-700 font-md">{{ data.headline.title }}</p>
                    </div>
                    <div class="footer-box">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 px-4" :class="{'border-right-dark': data.headline.st_second_title || data.headline.st_second_description}" v-if="data.headline.st_first_title || data.headline.st_first_description">
                                <p class="text-black fw-400 font-xssss">{{ data.headline.st_first_title }}</p>
                                <p class="text-black fw-600 font-xss">{{ data.headline.st_first_description }}</p>
                                <div class="b-bottom"></div>
                            </div>
                            <div class="col-lg-3 px-4" :class="{'border-right-dark': data.headline.st_third_title || data.headline.st_third_description}" v-if="data.headline.st_second_title || data.headline.st_second_description">
                                <p class="text-black fw-400 font-xssss">{{ data.headline.st_second_title }}</p>
                                <p class="text-black fw-600 font-xss">{{ data.headline.st_second_description }}</p>
                                <div class="b-bottom"></div>
                            </div>
                            <div class="col-lg-3 px-4" v-if="data.headline.st_third_title || data.headline.st_third_description">
                                <p class="text-black fw-400 font-xssss">{{ data.headline.st_third_title }}</p>
                                <p class="text-black fw-600 font-xss">{{ data.headline.st_third_description }}</p>
                                <div class="b-bottom"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['data', 'isLoad'],
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .banner-box{
        height: 650px !important;
    }
    .banner-box .text-box {
        top: 8% !important;
        left: 5% !important;
        width: 88% !important;
    }
    .banner-box .text-box .font-xsss{
        font-size: 10px !important;
    }
    .banner-box .text-box .font-md{
        font-size: 16px !important;
    }
    .banner-box .footer-box {
        top: 41% !important;
    }
    .border-right-dark {
        border-right: none !important;
    }
    .b-bottom {
        max-width: 100%;
        border-bottom: 1px solid #000;
    }
}
.border-right-dark {
    border-right: 1px solid #000;
}
.banner-box{
    background-color: #FFE29A;
    max-width: 100%;
    height: 520px;
}
.banner-box .text-box {
    width: 70%;
    background-image: url('/images/bg-blog/sec1-box-image.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    position: absolute;
    top: 32%;
    left: 15%;
    padding-left: 40px;
    padding-top: 10px;
}

.banner-box .footer-box {
    width: 90%;
    position: absolute;
    top: 63%;
    left: 5%;
}
</style>